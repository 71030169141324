body {
  @apply bg-snow-100;
}

.no-scroll {
  overflow: hidden;
}

/* typo */
.title-shadow {
  text-shadow: 0 3.109304666519165px 3.109304666519165px rgba(0, 0, 0, 0.25);
}
.text-shadow {
  text-shadow: 0 10.124688148498535px 10.124688148498535px rgba(0, 0, 0, 0.25);

}

/* SWIPER */
.swiper-button-next,
.swiper-button-prev {
  @apply text-lavender-100;
}
.effectCards {
  width: 308px;
  height: 360px;
}
.effectCards .swiper-slide {
  border-radius: 18px;
}

.effectCards .swiper-slide .card {
  justify-content: space-between;
  flex-direction: column;
  display: flex;
  align-items: center;
  border-radius: 18px;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
  height: 100%;
  width: 100%;
  transition: all .3s linear;
  text-align: center;
}

.effectCards .swiper-slide .card{
  background-color: #977CD7;
}

.effectCards .swiper-slide.swiper-slide-prev .card,
.effectCards .swiper-slide.swiper-slide-next .card{
  background-color: #BDA1FF;
}


.effectCards .swiper-slide-visible .card {
  @apply bg-lavender-200;
}

/* social features */


@media (min-width: 1024px) {
  .socialSlider {
    max-width: 400px;
  }
}
@media (max-width: 1023px) {
  .socialSlider {
    max-width: 300px;
  }
}
.socialSlider .swiper-slide {
  border-radius: 18px;
}

.socialSlider .swiper-slide .card {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
  /*height: 100%;
  width: 80%;*/
  transition: all .3s linear;
}

.swiper-3d .swiper-slide-shadow {
  background-color: transparent;
}/*

.socialSlider .swiper-slide.swiper-slide-prev .card,
.socialSlider .swiper-slide.swiper-slide-next .card{
  background-color: orange;
}

.socialSlider .swiper-slide-visible .card {
  background-color: red;
} */


.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  transition: width .7s ease;
  border-radius: 8px;
  background-color: #ffffff;
  opacity: .3;
}
.swiper-pagination-bullet-active {
  width: 16px;
  height: 8px;
  background-color: #ffffff;
  opacity: 1;
}
.featureSlider .swiper-pagination-bullet {
  @apply bg-lavender-200;
}
.featureSlider .swiper-slide {
  opacity: 0.2;
  transition: opacity 0.3s linear;
}
.featureSlider .swiper-slide h2,
.featureSlider .swiper-slide p {
  opacity: 0;
  transition: opacity 0.3s linear;

}
.featureSlider .swiper-slide-active h2,
.featureSlider .swiper-slide-active p,
.featureSlider .swiper-slide-active {
  opacity: 1;
  height: auto;
}


.heroSlider, .featureSlider, .socialSlider, .effectCards {
  padding-bottom: 70px;
}
.swiper-pagination {
  position: absolute;
  bottom: -60px;
}

.feature-section h3 {
  transition: all .3s linear;
}
.feature-section .active h3 {
  @apply text-lemon-100;
}
/*
@media (min-width: 1024px) {
  @keyframes pop{
    50%  {transform: scale(1.2);}
  }
  .feature-section .active h3 {
    animation: pop 0.3s linear 1;
  }
}
*/
@media (max-width: 1023px) {
  .feature-section .title {
    display: none;
  }
  .feature-section .title.active {
    display: block;
  }
}




.writeEmoji {
  -o-transform: scaleX(-1) scaleY(-1);
  -webkit-transform: scaleX(-1) scaleY(-1);
  transform: scaleX(-1) scaleY(-1);
  filter: FlipH FlipV;
  -ms-filter: "FlipH FlipV";
}

@media (min-width: 1024px) {
  .wrapper {
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    height: 100vh;
  }
  .wrapper section {
    scroll-snap-align: start;

  }
}

/* nav */
@media (max-width: 1023px) {
  .navbar {
    opacity: 0;
    transition: opacity .3s linear;
    pointer-events: none;
  }
  .navbar.visible {
    opacity: 1;
    pointer-events: all;
  }

  .burger {
    cursor: pointer;
  }
}

.custom-prev-arrow:after {
  content: url("data:image/svg+xml,%3Csvg width='22' height='28' viewBox='0 0 22 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.9999 4L3.99988 14L17.9999 24' stroke='%237742FF' stroke-width='8' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}
.swiper-button-disabled.custom-prev-arrow:after {
  content: url("data:image/svg+xml,%3Csvg width='22' height='28' viewBox='0 0 22 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.9998 24L3.99976 14L17.9998 4' stroke='%23B4B4B4' stroke-width='8' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}
.custom-next-arrow:after {
  content: url("data:image/svg+xml,%3Csvg width='22' height='28' viewBox='0 0 22 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.00012 24L18.0001 14L4.00012 4' stroke='%237742FF' stroke-width='8' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}
.swiper-button-disabled.custom-next-arrow:after{
  content: url("data:image/svg+xml,%3Csvg width='22' height='28' viewBox='0 0 22 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.00025 4L18.0002 14L4.00025 24' stroke='%23B4B4B4' stroke-width='8' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}
.custom-prev-arrow {
  left: -40px;
}
.custom-next-arrow {
  right: -40px;
}
@media (max-width: 1023px) {
  .custom-prev-arrow {
    left: 0px;
  }
  .custom-next-arrow {
    right: 0px;
  }
}

.custom-prev-arrow, .custom-next-arrow {
  @apply absolute top-[150px] z-50;
}

.star-line {
  @apply h-[3px] bg-lemon-100;
  width: calc(100% - 70px);
}
.left-line {margin-left: 70px;}
.right-line {margin-right: 70px;}
