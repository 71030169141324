.confetti-container {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  pointer-events: none;
  top: 0;
  left: 0;
}
[class|="confetti"] {
  position: absolute;
}

$colors: (#FF8080, #73FBD3, #7742FF);
.play-confetti {
  @for $i from 0 through 150 {
    $w: random(20);
    $l: random(100);
    .confetti-#{$i} {
      width: #{$w}px;
      height: #{$w*0.4}px;
      background-color: nth($colors, random(3));
      top: -10%;
      left: unquote($l+"%");
      opacity: random() + 0.5;
      transform: rotate(#{random()*460}deg);
      animation: drop-#{$i} unquote(3+random()+"s") unquote(random()+"s");
    }

    @keyframes drop-#{$i} {
      100% {
        top: 110%;
        left: unquote($l+random(15)+"%");
      }
    }
  }
}