body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on October 20, 2023 */

@font-face {
  font-family: 'circular_stdbook';
  src: url('./fonts/circularstd-book-webfont.woff2') format('woff2'),
  url('./fonts/circularstd-book-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'circular_stdbold';
  src: url('./fonts/circularstd-bold-webfont.woff2') format('woff2'),
  url('./fonts/circularstd-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'circular_stdblack';
  src: url('./fonts/circularstd-black-webfont.woff2') format('woff2'),
  url('./fonts/circularstd-black-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'circular_stdmedium';
  src: url('./fonts/circularstd-medium-webfont.woff2') format('woff2'),
  url('./fonts/circularstd-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'circular_stdmedium_italic';
  src: url('./fonts/circularstd-mediumitalic-webfont.woff2') format('woff2'),
  url('./fonts/circularstd-mediumitalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'uni_sansheavy';
  src: url('./fonts/unisans-heavy-webfont.woff2') format('woff2'),
  url('./fonts/unisans-heavy-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'uni_sansheavy_italic';
  src: url('./fonts/unisans-heavyitalic-webfont.woff2') format('woff2'),
  url('./fonts/unisans-heavyitalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  @apply font-stdbook;
  font-size: 18px;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;
}

@tailwind base;
@tailwind components;
@tailwind utilities;